import { HttpClient } from "compass-shared-services/src/interceptors";

export type customerDTO = { id: string; name: string };
export type AccountDTO = {
  userName: string;
  domain: string;
  useCustomDomain: boolean;
};

class AccountsService {
  private httpClient: HttpClient;

  constructor() {
    this.httpClient = new HttpClient({
      SERVER_URL: appConfig.ACS_URL,
    });
    this.httpClient.initHttp();
  }

  async getDomain(userEmail: string): Promise<AccountDTO> {
    return this.httpClient
      .post<AccountDTO>({
        url: appConfig.ACCOUNTS_SERVICE_PATH,
        payload: { EmailAddress: userEmail },
      })
      .then((response) => {
        return response;
      })
      .catch(async () => {
        return { userName: userEmail, domain: "local", useCustomDomain: true };
      });
  }
}

export default AccountsService;
