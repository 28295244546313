// General
import { AllowedModules, CompassAuth } from "compass-shared-services";
import { sessionStorageIdentifiers } from "compass-commons";
import { extractTabWithoutSubpath } from "../util/LoadingHelper";
// Model
import { UserGroupDTO } from "../model/userGroups/UserGroupDTO";

// Service
import UserManagerService from "./UserManagerService";

const {
  BASE_HTTP_PROTOCOL,
  B2C_RESOURCE_NAME,
  APP_ID,
  USER_MANAGER_SERVICE_PATH,
  REDIRECT_URI,
  LOGOUT_REDIRECT_URI,
} = appConfig;

const URL_PATHS = {
  GET_USERS: `${USER_MANAGER_SERVICE_PATH}/manager/user-groups/logged-user`,
};

const Authorization = () => {
  const compassAuth = CompassAuth(appConfig.SERVER_URL, appConfig.NAMESPACE);

  const getB2cPolicies = (policyName?, b2cDomain?) => {
    return compassAuth.createB2cPolicies(
      BASE_HTTP_PROTOCOL,
      b2cDomain,
      B2C_RESOURCE_NAME,
      policyName
    );
  };

  const createMsalConfig = (policeName?, b2cDomain?) => {
    const redirectOperations = {
      redirectUri: REDIRECT_URI,
      postLogoutRedirectUri: LOGOUT_REDIRECT_URI,
      navigateToLoginRequestUrl: true,
    };
    return compassAuth.createAuthConfig(
      APP_ID,
      getB2cPolicies(policeName, b2cDomain),
      redirectOperations
    );
  };

  const getAllowedModule = async (userManagerService: UserManagerService) => {
    try {
      const validateCustomer = await userManagerService.validateCustomer();
      if (!validateCustomer) {
        return "ask-to-be-invited";
      }

      return await compassAuth.userAllowedModule<UserGroupDTO>(
        URL_PATHS.GET_USERS,
        extractTabWithoutSubpath(
          sessionStorage.getItem(sessionStorageIdentifiers.CURRENT_TAB) || ""
        )
      );
    } catch (error) {
      return AllowedModules.UNAUTHORIZED;
    }
  };

  return {
    createMsalConfig,
    getB2cPolicies,
    getAllowedModule,
  };
};

const CompassAuthorization = Authorization();
export default CompassAuthorization;
