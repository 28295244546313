// General
import React, { Suspense, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { withI18n, getLocalStorage } from "compass-commons";
// Styles
import "./app.module.css";
// Components
import Spinner from "../spinner/Spinner";
// Services
import CompassAuthorization from "../../services/Authorization";
// Utils
import { lazyMinLoadTime } from "../../util/LoadingHelper";
import AccountsService from "../../services/AccountsService";

const {
  ASSET_STORE,
  LOGIN_IDP_DOMAIN_HINT,
  DMS_UI_LANGUAGE,
  COMPASS_BASE_PREFIX,
  USER_MANAGER_SERVICE_PATH,
  SERVER_URL,
  EULA_URL,
  BASE_POLICY_NAME,
  FEDERATED_B2CDOMAIN,
  LOCAL_B2CDOMAIN,
  NAMESPACE,
} = appConfig;

// In case we starting a standalone app, Single spa is not provided
const POLICY = "policy";
const DOMAIN = "domain";
export const USER_EMAIL = "userEmail";
const App = (): JSX.Element => {
  useEffect(() => {
    sessionStorage.setItem("namespace", NAMESPACE); // this is temporary fix for widget library.
  }, []);

  const config = CompassAuthorization.createMsalConfig(
    getLocalStorage(POLICY, NAMESPACE),
    getLocalStorage(DOMAIN, NAMESPACE)
  );
  const msalInstance = new PublicClientApplication(config);
  const accountsService = new AccountsService();
  if (getLocalStorage(USER_EMAIL, NAMESPACE)) {
    accountsService
      .getDomain(getLocalStorage(USER_EMAIL, NAMESPACE))
      .then((accountData) => {
        const policyName =
          BASE_POLICY_NAME + accountData.domain.replace(/\./g, "_");
        const b2cDomain = accountData.useCustomDomain
          ? LOCAL_B2CDOMAIN
          : FEDERATED_B2CDOMAIN;
        msalInstance.getConfiguration().auth.authority =
          CompassAuthorization.getB2cPolicies(policyName, b2cDomain).authority;
        msalInstance.getConfiguration().auth.knownAuthorities = [b2cDomain];
      });
  }

  const DelayedLogin = lazyMinLoadTime(() => import("../login/Login"), 1000);
  return (
    <BrowserRouter>
      <MsalProvider instance={msalInstance}>
        <Suspense fallback={<Spinner />}>
          <DelayedLogin
            eulaUrl={EULA_URL}
            compassBase={COMPASS_BASE_PREFIX}
            assetStore={ASSET_STORE}
            loginIdp={LOGIN_IDP_DOMAIN_HINT}
            dmsUiLanguage={DMS_UI_LANGUAGE}
            userManagerServicePath={USER_MANAGER_SERVICE_PATH}
            serverUrl={SERVER_URL}
            msalConfig={msalInstance.getConfiguration()}
            federatedDomain={FEDERATED_B2CDOMAIN}
            localDomain={LOCAL_B2CDOMAIN}
            basePolicyName={BASE_POLICY_NAME}
            namespace={NAMESPACE}
          />
        </Suspense>
      </MsalProvider>
    </BrowserRouter>
  );
};

export default withI18n(App);
