import React from "react";
import "./spinner.module.css";

interface SpinnerProps {
  height?: number;
  width?: number;
}

const { ASSET_STORE } = appConfig;
const SPINNER = `${ASSET_STORE}login-ui/dms_spinner2.gif`;
const Spinner = (props?: SpinnerProps): JSX.Element => {
  const { width, height } = props;

  return (
    <div className="spinner">
      <img src={SPINNER} alt="cur" height={width} width={height} />
    </div>
  );
};

Spinner.defaultProps = {
  height: 90,
  width: 90,
};

export default Spinner;
