import { ComponentType, lazy } from "react";
import jwtDecode, { JwtPayload } from "jwt-decode";
import { getAccountInfo, sessionStorageIdentifiers } from "compass-commons";

const lazyMinLoadTime = <T extends ComponentType<any>>(
  factory: () => Promise<{ default: T }>,
  minLoadTimeMs = 2000
) =>
  lazy(() =>
    Promise.all([
      factory(),
      new Promise((resolve) => setTimeout(resolve, minLoadTimeMs)),
    ]).then(([moduleExports]) => moduleExports)
  );

const getTokenExpiration = (token: string) => {
  const decoded = jwtDecode<JwtPayload>(token);
  return decoded?.exp * 1000;
};

const setActiveAccount = (idToken: string) => {
  sessionStorage.setItem(
    sessionStorageIdentifiers.ACCOUNT_INFO,
    JSON.stringify(getAccountInfo(idToken))
  );
};

const getCurrentTabPath = (
  pathToMatch: string,
  saveInStorage = true
): string => {
  const hrefMatch = window.location.pathname.match(pathToMatch);
  // Start reading after the last dash
  const startMatchIndex = (hrefMatch?.index || 0) + pathToMatch.length + 1;

  const currentTab = window.location.pathname.slice(
    startMatchIndex,
    window.location.pathname.length
  );

  if (saveInStorage && currentTab !== "")
    sessionStorage.setItem(sessionStorageIdentifiers.CURRENT_TAB, currentTab);
  return currentTab;
};

const extractTabWithoutSubpath = (path?: string): string => {
  // Extract all types of endUrl
  const findAnySuffixBar = path?.match(/[/?#]/);
  const suffixIndex = findAnySuffixBar?.index || path?.length || 0;
  // Check if lastIndex is less than the startIndex.
  // If thats the case then we want to parse till the end of the string
  return path?.slice(0, suffixIndex);
};

// eslint-disable-next-line import/prefer-default-export
export {
  lazyMinLoadTime,
  getTokenExpiration,
  setActiveAccount,
  getCurrentTabPath,
  extractTabWithoutSubpath,
};
