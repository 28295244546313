import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import { ICompassI18n, LocalizationNS } from "compass-commons";
import { CompassI18n, defaultNS } from "compass-shared-services";
import App from "./components/app/App";
import "compass-commons/lib/css/compass-light-core.css";

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: App,
  errorBoundary(_err, _info, _props) {
    // Customize the root error boundary for your microfrontend here.
    return null;
  },
});

export const mount = async <T extends { CompassI18n: ICompassI18n }>(
  props: T
) => {
  if (props.CompassI18n) {
    const { loadMoreTranslations } = props.CompassI18n;
    await loadMoreTranslations(LocalizationNS.LOGIN, false);
  } else {
    const defaultCompassI18n = CompassI18n(appConfig.ASSET_STORE);
    await defaultCompassI18n.init({ ns: defaultNS });
    // eslint-disable-next-line no-param-reassign
    props.CompassI18n = defaultCompassI18n as ICompassI18n;
  }
  return lifecycles.mount(props);
};

export const { bootstrap, unmount } = lifecycles;
